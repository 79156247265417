// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-de-fotogallerie-js": () => import("./../../../src/pages/de/fotogallerie.js" /* webpackChunkName: "component---src-pages-de-fotogallerie-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-kontact-js": () => import("./../../../src/pages/de/kontact.js" /* webpackChunkName: "component---src-pages-de-kontact-js" */),
  "component---src-pages-de-presliste-js": () => import("./../../../src/pages/de/presliste.js" /* webpackChunkName: "component---src-pages-de-presliste-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-photogallery-js": () => import("./../../../src/pages/en/photogallery.js" /* webpackChunkName: "component---src-pages-en-photogallery-js" */),
  "component---src-pages-en-pricelist-js": () => import("./../../../src/pages/en/pricelist.js" /* webpackChunkName: "component---src-pages-en-pricelist-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-hr-contact-js": () => import("./../../../src/pages/hr/contact.js" /* webpackChunkName: "component---src-pages-hr-contact-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-photogallery-js": () => import("./../../../src/pages/hr/photogallery.js" /* webpackChunkName: "component---src-pages-hr-photogallery-js" */),
  "component---src-pages-hr-pricelist-js": () => import("./../../../src/pages/hr/pricelist.js" /* webpackChunkName: "component---src-pages-hr-pricelist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-pl-cennik-js": () => import("./../../../src/pages/pl/cennik.js" /* webpackChunkName: "component---src-pages-pl-cennik-js" */),
  "component---src-pages-pl-galeria-zdjec-js": () => import("./../../../src/pages/pl/galeria-zdjęc.js" /* webpackChunkName: "component---src-pages-pl-galeria-zdjec-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */)
}

